import { DateTime } from "luxon"
import TemplateUtil from "packs/utils/TemplateUtil"
import Util from "packs/utils/Util"
import Appointment from "./Appointment"
import RoomMember from "./RoomMember"

export default class MailFlow {
    constructor(
        public id: string,
        // def_type: create_room, fix, feedback
        // 公開ページ: create_room, fixed_appointment, feedback
        // 限定公開ページ: inquire_on_public_room, feedback, custom
        public def_type: string,

        public subject: string,
        public content: string,
        public sms_content: string,

        public emails: string[],
        public send_to: string[],

        public button_link: string,
        public button_name: string,
        public button_public_room_id: string,

        public display_parent_room_link_button: boolean,

        public timing_type: string,
        public send_timing: number,
        public send_timing_around: string, // before, after

        public template_public_id: string,
        public is_active: boolean,
        public show_qa: boolean,

        // 事前埋めで利用する項目.
        public mail_display_type: string,
        public use_template: boolean,
        public systemUpdatedAt: number
    ) {}

    static fetchFromJson(filesJson: any[]): MailFlow[] {
        let files = []
        for (let _file of filesJson || []) {
            let file: MailFlow = _file
            files.push(file)
        }
        return files
    }

    static createDefault(defName = null): MailFlow {
        let now = DateTime.local()
        let mf = new MailFlow(
            null,
            defName,
            null, // this.getSubject(defName),
            null, // this.getContent(defName),
            null,
            null,
            [`all`],
            null,
            null,
            null,
            true,

            null,
            0,
            `after`,

            null,
            true,
            true,
            `rich`,
            null,
            now.toSeconds()
        )
        return mf
    }

    static copy(o: MailFlow): MailFlow {
        let clone = Object.assign(Object.create(Object.getPrototypeOf(o)), o)
        return clone
    }

    // 事前埋めでメールを作成する際の内容を入れておきます。
    static createDefaultForUser(mem: RoomMember): MailFlow {
        let autoLinkText = TemplateUtil.autoLinkText
        let _mf = this.createDefault(`create_room`)
        _mf.show_qa = false
        _mf.is_active = true
        _mf.mail_display_type = `simple`
        _mf.subject = `日程調整の依頼`
        if (mem) {
            _mf.content = `${mem.name}様\n\nお世話になっております。\n\nこちらから日程を選択してください。\n${autoLinkText}`
        } else {
            _mf.content = `<<出席者-1-氏名>>様\n\nお世話になっております。\n\nこちらから日程を選択してください。\n${autoLinkText}`
        }
        _mf.use_template = false
        return _mf
    }

    static filledTemplate(mem: RoomMember, mf: MailFlow) {
        let autoLinkText = TemplateUtil.autoLinkText
        let _mf = this.createDefault(`create_room`)
        _mf.show_qa = false
        _mf.is_active = true
        _mf.mail_display_type = `simple`
        _mf.subject = TemplateUtil.insertTemplateText(
            mf.subject || `日程調整の依頼`,
            mem ? [mem] : [],
            null,
            null,
            mem,
            null,
            null,
            `custom`,
            `simple`,
            null,
            null
        )
        let content = mf.content
        if (!content.includes(autoLinkText)) {
            content = `${content}\n${autoLinkText}`
        }
        // _mf.content = rcm.insertTemplateText(mf.content, mem, null, null, mem, null, null, null, `simple`, null, null)
        _mf.content = TemplateUtil.insertTemplateText(content, [mem], null, null, mem, null, null, `custom`, `simple`, null, null)
        // `${mem.name}様\n\nお世話になっております。\n\n${text}\n${autoLinkText}`

        _mf.use_template = false
        return _mf
    }

    static createForPublicPage() {
        let fb = this.createFeedbackFlow()
        return [this.createDefault(`fix`), fb]
    }

    static createMailFlows(roomType: string = `public_room`) {
        let timingsDicArray = Util.timingsDicArray(roomType)
        let mfs = []
        for (let dic of timingsDicArray) {
            if (dic.type == `feedback`) {
                mfs.push(this.createFeedbackFlow())
            } else {
                mfs.push(this.createDefault(dic.type))
            }
        }
        // let fb = this.createFeedbackFlow()
        // return [this.createDefault(`create_room`), this.createDefault(`fix`), fb]
        return mfs
    }

    static createFeedbackFlow() {
        let flow = this.createDefault(`feedback`)
        flow.is_active = false
        flow.send_to = [`invitees`]
        flow.button_name = `アンケートに答える`
        // 終了後すぐ
        flow.timing_type = `after_start`
        flow.send_timing = 0

        return flow
    }

    static getSubject(defName: string, appo: Appointment = null) {
        let appoName = appo && appo.name ? appo.name : `打ち合わせ`
        if (defName == `create_room`) {
            return `「${appoName}」の日程調整依頼が届いています`
        } else if ([`inquire_on_public_room`].includes(defName)) {
            return `ありがとうございます、受付完了しました`
        } else if ([`fixed_appointment`, `fix`].includes(defName)) {
            return `日程が確定しました`
        } else if ([`feedback`].includes(defName)) {
            return `本日はありがとうございました。`
        } else if ([`the_day_before`].includes(defName)) {
            return `明日の予定をお知らせします`
        } else if ([`start_appointment`].includes(defName)) {
            return `日程調整「${appoName}」を開始`
        } else if ([`cancel_appointment`].includes(defName)) {
            return `日程調整「${appoName}」をキャンセルしました`
        } else if ([`one_day_before_deadline`].includes(defName)) {
            return `有効期限が切れる日程調整があります`
        } else if ([`request_other_possible_dates`].includes(defName)) {
            return `別日程の提出をリクエストしています`
        } else if ([`remind_to_share_schedule`].includes(defName)) {
            return `日程候補の共有を待っています`
        } else if ([`auto_remind`].includes(defName)) {
            return `<<出席者-1-氏名>>様からの日程の共有をお待ちしております`
        } else if ([`shared_calendar`].includes(defName)) {
            return `<<出席者-1-氏名>>様から日程候補のご提案がありました。`
        } else if ([`custom`].includes(defName)) {
            return `件名を設定してください`
        }
        return ``
    }

    static isSame(mf1: MailFlow, mf2: MailFlow): boolean {
        if (!mf1 || !mf2) return false
        return mf1.def_type == mf2.def_type && mf1.id == mf2.id && mf1.systemUpdatedAt == mf2.systemUpdatedAt
    }

    static isCustom(mf: MailFlow): boolean {
        if (!mf) return false
        return mf.def_type == `custom`
    }
}
