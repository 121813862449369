import axios from "axios"
import Util from "packs/utils/Util"
import Notice from "packs/models/Notice"
import AppealContent from "packs/models/AppealContent"
import FormField from "packs/models/FormField"
import { funcName, Logger } from "packs/common"
import NotificationControl from "packs/utils/NotificationControl"
import RoomMember from "packs/models/RoomMember"
import AssignCondition from "packs/models/AssignCondition"
import Room from "packs/models/Room"
import FormFieldConverter from "packs/models/FormFieldConverter"

export default class AssignForm {
    constructor(
        public title: string,
        public id: string,
        public form_id: string,
        public public_id: string,
        public custom_form_id: string,
        public is_owner: boolean,
        public is_owner_group: boolean,
        public status: number,
        public status_name: string,
        public form_fields: FormField[],
        public owner: RoomMember,
        public has_detail: boolean,
        public assign_conditions: AssignCondition[],
        public updated_at: number,
        public created_at: number,
        public button_bg_color: string,
        public no_match_destination_type: string,
        public no_match_destination_value: string,
        public show_waaq_tos: boolean,
        public show_waaq_pp: boolean,
        public terms_of_service_name: string,
        public privacy_policy_name: string,
        public terms_of_service_url: string,
        public privacy_policy_url: string,

        public form_submit_button_name: string,
        public form_submit_button_bg_color: string,
        public form_submit_button_text_color: string,

        public form_style_setting: { [key: string]: string },
        public has_detail_converters: boolean,
        public form_field_converters: FormFieldConverter[]
    ) {}

    static copy(o: AssignForm): AssignForm {
        // let clone = Object.assign(Object.create(Object.getPrototypeOf(o)), o)
        // return clone
        return JSON.parse(JSON.stringify(o))
    }

    static createDefault(name: string = null) {
        let pubId = Util.getRandomPublicId(`AF`)
        let noMatchText = `お問い合わせいただきありがとうございました。`
        const form = new AssignForm(
            name,
            null,
            null,
            pubId,
            null,
            true,
            true,
            0,
            "停止中",
            [],
            null,
            true,
            null,
            null,
            null,
            null,
            "end_with_message",
            noMatchText,
            true,
            true,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            false,
            null
        )
        return form
    }

    static fetchFromJsonOne(aformJson: any): AssignForm {
        if (!aformJson) return null
        let aform: AssignForm = aformJson
        if (Util.isPresent(aform.form_fields)) {
            let _fs = FormField.fetchFromJson(aform.form_fields)
            aform.form_fields = _fs
        }
        return aform
    }

    static fetchFromJson(groupsJson: any): AssignForm[] {
        if (!groupsJson) return []
        let aforms = []
        for (let _aform of groupsJson) {
            let aform: AssignForm = AssignForm.fetchFromJsonOne(_aform)
            aforms.push(aform)
        }
        return aforms
    }

    static getAllAssignForms() {
        return axios
            .post(`${Util.prefixUrl}/assign_forms/index`)
            .then(result => {
                Logger(`AssignForm.${funcName()} result: ${Util.output(result.data)}`)
                const aforms: AssignForm[] = AssignForm.fetchFromJson(result.data.aforms)
                return aforms
            })
            .catch(err => {
                Logger(`AssignForm.${funcName()} err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static save(aform: AssignForm) {
        return axios
            .post(`${Util.prefixUrl}/assign_forms/save_assign_form`, aform)
            .then(result => {
                Logger(`AssignForm.${funcName()} result: ${Util.output(result.data)}`)
                Notice.message = `${result.data.message}`
                const _aform: AssignForm = AssignForm.fetchFromJsonOne(result.data.aform)

                return _aform
            })
            .catch(err => {
                Logger(`AssignForm.${funcName()} err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static getAformDetail(aformId: string, needDetail: boolean = true): Promise<AssignForm> {
        return axios
            .get(`${Util.prefixUrl}/assign_forms/show`, { params: { id: aformId, need_detail: needDetail } })
            .then(result => {
                Logger(`AssignForm.${funcName()} result: ${Util.output(result.data)}`)
                const _aform: AssignForm = AssignForm.fetchFromJsonOne(result.data.aform)
                return _aform
                // return result.data.aform
            })
            .catch(err => {
                Logger(`AssignForm.${funcName()} err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static search(params) {
        return axios
            .post(`${Util.prefixUrl}/assign_forms/search`, params)
            .then(result => {
                Logger(`AssignForm.${funcName()} result: ${Util.output(result.data)}`)
                const aforms: AssignForm[] = AssignForm.fetchFromJson(result.data.aforms)
                return aforms
            })
            .catch(err => {
                Logger(`AssignForm.${funcName()} err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static complete(aformId: string, params: any) {
        Object.assign(params, { assign_form_id: aformId })

        return axios
            .post(`${Util.prefixUrl}/assign_forms/complete`, params)
            .then(result => {
                Logger(`AssignForm.${funcName()} result: ${Util.output(result.data)}`)
                // const member: RoomMember = result.data.member

                const userInfo: RoomMember = result.data.user_info ? RoomMember.fetchFromJson([result.data.user_info])[0] : null
                const res_room: Room = result.data.room ? Room.fetchFromJson([result.data.room], userInfo?.user_id)[0] : null

                const roomLink: string = result.data.room_link
                let guestKey = null
                if (roomLink) {
                    if (Util.isEmbeds() || Util.isBoard()) {
                        guestKey = roomLink.split(`/s/`)[1]
                    }
                    history.replaceState("", "", `${roomLink}`)
                }

                return {
                    goto_type: result.data.goto_type,
                    ret_params: result.data.ret_params,
                    room: res_room,
                    guestKey: guestKey,
                    userInfo: userInfo,
                }
            })
            .catch(err => {
                Logger(`AssignForm.${funcName()} err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return null
            })
    }

    static delete(aform: AssignForm) {
        let params = { id: aform.id }
        return axios
            .delete(`${Util.prefixUrl}/assign_forms/delete`, { data: params })
            .then(result => {
                Logger(`AssignForm.${funcName()} result: ${Util.output(result.data)}`)
                Notice.message = `${result.data.message}`
                return result.data
            })
            .catch(err => {
                Logger(`AssignForm.${funcName()} err: ${err.message} ${Util.output(err.response)}`)
                NotificationControl.showErrorMessage(err)
                return false
            })
    }

    static copyFields(fields: FormField[], pubIdReplaceDic: { [key: string]: string }) {
        let _fields = (fields || []).map(field => {
            let f = FormField.copy(field)
            f.field_id = null
            let newId = Util.getRandomPublicId(`FF`)

            pubIdReplaceDic[field.public_id] = newId
            f.public_id = newId
            return f
        })
        return { _fields, pubIdReplaceDic }
    }

    /**
     * フォームをコピーする際、IDをリセットします。
     * @param form
     * @returns AssignForm
     */
    static copyForm(form: AssignForm): AssignForm {
        let pubIdReplaceDic = {}
        let aformPubId = Util.getRandomPublicId(`AF`)
        let _form = AssignForm.copy(form)
        _form.id = null
        _form.public_id = aformPubId
        _form.form_id = null
        _form.title = `${form.title} (コピー)`
        let _dic = AssignForm.copyFields(_form.form_fields, pubIdReplaceDic)
        _form.form_fields = _dic._fields
        pubIdReplaceDic = Object.assign(pubIdReplaceDic, _dic.pubIdReplaceDic)

        _form.assign_conditions = (_form.assign_conditions || []).map(cond => {
            cond.id = null
            cond.public_id = Util.getRandomPublicId(`ASC`)
            cond.answer_condition_groups = (cond.answer_condition_groups || []).map(acg => {
                acg.id = null
                acg.public_id = Util.getRandomPublicId(`ACG`)
                acg.answer_conditions = (acg.answer_conditions || []).map(ac => {
                    ac.id = null
                    ac.public_id = Util.getRandomPublicId(`ANC`)
                    ac.field_public_id = pubIdReplaceDic[ac.field_public_id]
                    return ac
                })
                return acg
            })
            return cond
        })
        return _form
    }
}
